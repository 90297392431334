import { Injectable, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { PackageEditForm } from './package-edit-form.interface';

@Injectable({ providedIn: 'root' })
export class PackageEditFormService {
  #fb = inject(NonNullableFormBuilder);

  public readonly form: FormGroup<PackageEditForm> = this.#fb.group({
    name: this.#fb.control('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    amount: this.#fb.control(0, [Validators.required, Validators.min(0)]),
    benefits: this.#fb.array<FormControl<string>>(
      [this.#fb.control('', [Validators.required, Validators.maxLength(20)])],
      [Validators.minLength(1), Validators.maxLength(5)],
    ),
  });

  public resetBenefits(): void {
    this.form.controls.benefits.clear();
    this.form.controls.benefits.push(
      this.#fb.control('', [Validators.required, Validators.maxLength(20)]),
    );
  }
}
