import { createAction, props } from '@ngrx/store';

export const loadPackage = createAction(
  '[Package Edit] Load package',
  props<{ id: number }>(),
);

export const resetPackageForm = createAction(
  '[Package Edit] Reset package form',
);

export const back = createAction('[Package Edit] Back Clicked');

export const savePackage = createAction(
  '[Package Edit] Save package',
  props<{ id: number; locationId: number }>(),
);

export const createPackage = createAction(
  '[Package Edit] Create package',
  props<{ locationId: number }>(),
);

export const addBenefit = createAction('[Package Edit] Add benefit');

export const removeBenefit = createAction(
  '[Package Edit] Remove benefit',
  props<{ index: number }>(),
);
